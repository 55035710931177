import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatSnackBar } from '@angular/material';

import { RoutePath } from '../../route-path';
import { Booking } from './../../../models/booking/booking';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { UpsellSummary, Item } from 'src/modules/models/item/item';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { PackageFilter } from 'src/modules/models/booking/common';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { environment } from 'src/environments/environment';

import { SystemService } from '../../../services/system.service';
import { AssetService } from 'src/modules/services/asset.service';
import { PackageService } from 'src/modules/services/package.service';
import { ItemService } from 'src/modules/services/item.service';

import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { UpsellComponent } from 'src/modules/booking-portal/booking-cart/upsell/upsell.component';
import { QuestionnaireComponent } from 'src/modules/booking-portal/modals/questionnaire/questionnaire.component';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { WarningMessageComponent } from 'src/modules/shared/warning-message/warning-message.component';
import { SystemConfig } from 'src/modules/system-configuration'
import { Utility } from 'src/modules/utility';

import { Store, ICart, DeleteFacility, ValidateFacilities, UpdateBookingPackage, UpdateFacility, UpdateBookingEventName, AddDocument, DeleteDocument, UpdateFacilityPrice, DeleteFacilityPrice } from 'src/modules/store/index';
import { Observable } from "rxjs";
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';

import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

@Component({
    selector: 'opt-mmc-cart',
    templateUrl: './mmc-cart.component.html'
})

export class MmcCartComponent extends BookingCommonComponent implements OnInit, OnDestroy {
    @ViewChild('fileInputTag') fileInputTagVariable: ElementRef; status: boolean = false;
    @Input() showQuestionPop: string;
    @Output() navigated: EventEmitter<number> = new EventEmitter<number>();

    protected aFormGroup: FormGroup;
    value: boolean = false;
   // cart$: Observable<ICart>;
    cart_subscriber: any;
    selectedBusinessArea: string;
    booking = new Booking;
    facilitySummaries: FacilitySummary[] = [];
    packageClasses: any[] = [];
    bookingData: AppSetting;
   
    packages: BespokePackage[] = [];
    public files: UploadFile[] = [];
    public fileSelector: ElementRef;
    //selectedClass: number;
    selectedPackage: string;
    selectedPackageDetail: BespokePackage;
    defaultEventName: string;
    isValidPackage: boolean;
    selectedFiles: AdHocDocument[] = [];
    getImages = true;
    ledgerNumber: string;
    selectedPackageClass: any;
    packageFilter: PackageFilter;
    packageClassRestValue: boolean = true;
    configurationDisplayMode: number;
    // ImmediateConfirmation 
    //isLoggedIn: boolean = false;
    isSystemAllowedImmediateConfirmation: boolean=false;
    systemDefaultPriceConsessionId: number;
    //isAllImmediateConfirmed: boolean;
    //customerDefaultPriceConsessionId: number;
    packageSelected: BespokePackage;
    isConfigurationEnabled:boolean = false;
    // ImmediateConfirmation
    hasZeroPrice: boolean = false;
    isUpsellAvailableForConcession: boolean = false;
    documentUpldSize: any;
    constructor(private cd: ChangeDetectorRef, private router: Router, private _systemService: SystemService, private store: Store<any>,
        public dialog: MatDialog, private _itemService: ItemService, private _sysConfig: SystemConfig, private _assetService: AssetService,
        private _packageService: PackageService, public snackBar: MatSnackBar, private sanitizer: DomSanitizer, private formBuilder: FormBuilder,
        private scrollTopService: ScrollTopService)
    {
       // this.cart$ = this.store.select('cart');
        super(store, _itemService, snackBar);
        this.booking = new Booking();

        //this.isLoggedIn = false;
        //this.isSystemAllowedImmediateConfirmation = false;
        //this.systemDefaultPriceConsessionId = 0;
        //this.isAllImmediateConfirmed = false;
        //this.customerDefaultPriceConsessionId = 0;
        this.bookingData = (environment.AppSetting as AppSetting);
        this.documentUpldSize = this.bookingData.BookingConfig.MaximumDocUploadSize + "MB";

    }



    ngOnInit() {
        super.ngOnInit();
        var thisPage = this;
        this.aFormGroup = this.formBuilder.group({
            EventName: ['', Validators.required],
            ledgerNumber: ['']
        });

        var deleteFacilities: FacilitySummary[] = [];
        this._sysConfig.loadAppConfiguration().then(() => {
            // this.store.dispatch(new ValidateFacilities());
            this.packageClasses = environment.PackageClasses.filter(cls => cls.Id != "0");

            this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
            this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;
            this.configurationDisplayMode = environment.ConfigurationSelection.DisplayMode;
            this.isConfigurationEnabled = environment.ConfigurationSelection.Enabled;
            this.cart_subscriber = this.cart$.subscribe(state => {
                console.log(state)
                this.booking = state.booking;
                this.packages = state.bespokePackages;
                this.selectedFiles = state.AddedDocuments != undefined ? state.AddedDocuments : [];
                this.facilitySummaries = state.addedFacilites;
                this.hasZeroPrice = state.hasZeroPrice;
                if (state.packageFilter != undefined && state.packageFilter != null) {
                    this.packageFilter = state.packageFilter;
                    if (state.packageFilter.Package) {
                        this.selectedPackage = state.packageFilter.Package.toString();
                        this.selectedPackageDetail = state.packageFilter.PackageDetail;
                        console.log("state.packageFilter.PackageDetail", state.packageFilter.PackageDetail);
                    }
                    this.defaultEventName = state.packageFilter.EventName;
                    if (state != undefined && state.packageFilter != undefined && state.packageFilter != null) {
                        this.packageClasses = state.packageFilter.FilteredPackageClasses;
                        //this.selectedPackageClass = this.packageClasses.find(a => a.Id == state.packageFilter.PackageClass);
                    }                       
                    
                }
                /*
                this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
               
                this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
                this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
               
                if (state.packageFilter != undefined && state.packageFilter != null) {                  
                    if (state.packageFilter.PackageClass > 0) this.selectedClass = state.packageFilter.PackageClass;
                    else {
                        let selectedClassDetail = this.packageClasses.find(c => c.PriceMinConsessionId == this.systemDefaultPriceConsessionId || c.PriceMaxConsessionId == this.systemDefaultPriceConsessionId);
                        if (selectedClassDetail != undefined) this.selectedClass = selectedClassDetail.Id;
                    }
                } else {
                    if (this.isLoggedIn && this.customerDefaultPriceConsessionId !== 0) {
                        let selectedClassDetail = this.packageClasses.find(c => c.PriceMinConsessionId == this.customerDefaultPriceConsessionId || c.PriceMaxConsessionId == this.customerDefaultPriceConsessionId);
                        if (selectedClassDetail != undefined) this.selectedClass = selectedClassDetail.Id;
                    }
                    else if (this.systemDefaultPriceConsessionId != 0) {
                        let selectedClassDetail = this.packageClasses.find(c => c.PriceMinConsessionId == this.systemDefaultPriceConsessionId || c.PriceMaxConsessionId == this.systemDefaultPriceConsessionId);
                        if (selectedClassDetail != undefined) this.selectedClass = selectedClassDetail.Id;
                    }
                    else {
                        if (this.facilitySummaries.length == 1) {
                            let minConcessionId = (this.facilitySummaries[0].minPrice != undefined && this.facilitySummaries[0].minPrice != null) ? this.facilitySummaries[0].minPrice!.concessionId : 0;
                            let maxConcessionId = (this.facilitySummaries[0].maxPrice != undefined && this.facilitySummaries[0].maxPrice != null) ? this.facilitySummaries[0].maxPrice!.concessionId : 0;
                            let selectedClassDetail = this.packageClasses.find(c => c.PriceMinConsessionId == minConcessionId || c.PriceMaxConsessionId == maxConcessionId);

                            if (selectedClassDetail != undefined) this.selectedClass = selectedClassDetail.Id;
                            else this.selectedClass = 1;
                        }
                        //  this.packageClassChange();
                    }
                  //  this.packageClassChange();
                }
                */
             

                if (this.getImages) {
                    if (this.facilitySummaries != undefined && this.facilitySummaries.length > 0) {
                        var res = this._assetService.getVenueRoomsImagesForCart(this.facilitySummaries).toPromise();
                        if (res != null) {
                            res.then(data => { this.facilitySummaries = data; });
                        }
                    }
                    this.getImages = false;
                }
                //this.packageClassRestValue = false;
                //this.packageClassRestValue = true;
            });

            if (this.showQuestionPop) {
                if (!(this.facilitySummaries != undefined && this.facilitySummaries.length > 0)) return;

                let facilityindex = Math.max.apply(Math, this.facilitySummaries.map(function (o) { return o.index; }));
                let facility = this.facilitySummaries.find(f => f.index == facilityindex);

                if (facility != undefined && facility.item != undefined && facility.item.question != undefined && facility.item.question.length > 0) {
                    this.popupQuestion(facility, facility.item, false);
                }
            }
            if (this.facilitySummaries != undefined && this.facilitySummaries.length == 1 && this.packageFilter.PackageClass == undefined && this.selectedClass != undefined)
                this.packageSelectorChange();

            //if ((this.isAllImmediateConfirmed && this.isLoggedIn) && (this.customerDefaultPriceConsessionId != 0 && this.systemDefaultPriceConsessionId != this.customerDefaultPriceConsessionId)) {
            //    this.getItemPrices(this.facilitySummaries);
            //}
            //console.log("this.isLoggedIn ---", this.isLoggedIn);
            //console.log("this.customerDefaultPriceConsessionId ---", this.customerDefaultPriceConsessionId)
            //console.log("this.systemDefaultPriceConsessionId ---", this.systemDefaultPriceConsessionId)
            //if (this.isLoggedIn && (this.customerDefaultPriceConsessionId != 0 && this.customerDefaultPriceConsessionId != null && this.systemDefaultPriceConsessionId != this.customerDefaultPriceConsessionId)) {
            //    this.getItemPrices(this.facilitySummaries);
            //}
        });
        this.cd.detectChanges();
        this.preventWindowDragEvent();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.scrollTopService.scrollPageTop();
        }, 2300);
    }
    ngOnDestroy() {
        if (this.cart_subscriber) this.cart_subscriber.unsubscribe();
    }

    updateMyTextModel(v) {
        this.aFormGroup.controls.EventName.markAsTouched();
        this.snackBar.openFromComponent(AlertMessageComponent, {
            duration: 3000,
            verticalPosition: 'top',
            data: ["Your request could not be processed. Event name is mandatory. Please enter the event name."]
        });
    }

    eventNameChange(val) {
        if (val != undefined && val != null) {
            this.store.dispatch(new UpdateBookingEventName(val));
        }
    }

    packageSelectorChange(selectedPackage: BespokePackage = null) {

        if (selectedPackage) {
            this.packageFilter.PackageDetail = selectedPackage;
        }
        let filter = this.packageFilter;
        filter.BussinessAreaId = +this.selectedBusinessArea;
        filter.PackageClass = this.selectedClass;
        filter.Package = +this.selectedPackage;
        //filter.PackageDetail = this.selectedPackageDetail;
        filter.snackBar = this.snackBar;
        filter.EventName = this.defaultEventName;
        filter.ledgerNumber = this.ledgerNumber;
        this.store.dispatch(new UpdateBookingPackage(filter));
    }

    packageClassChange() {
        var thisPage = this;
        if (this.facilitySummaries && this.facilitySummaries.length > 0) {
            let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(this.facilitySummaries, this.selectedClass);
            let selectedPackageClassDetail = environment.PackageClasses.find(a => a.Id == this.selectedClass);
            let _appSetting = environment.AppSetting as AppSetting;
            if (_appSetting.BookingConfig.PriceDisplayOption == 1 || 
                _appSetting.BookingConfig.PriceDisplayOption == 2 ||
                isPriceConcessionAvailable.isHasValidMinPriceConcession || 
                isPriceConcessionAvailable.isHasValidMaxPriceConcession ||
                environment.IsAllowMultiplePriceConsession || 
                (selectedPackageClassDetail.PriceMinConsessionId == "" && selectedPackageClassDetail.PriceMaxConsessionId == "")
                ) {
                if (environment.PriceDisplayOption == 3) {
                    // this.priceCalculation();
                    this.calculateCartPrices();
                }

                
                if (selectedPackageClassDetail.WarningMessage && selectedPackageClassDetail.WarningMessage!="") {
                    this.dialog.open(WarningMessageComponent, {
                        data: selectedPackageClassDetail.WarningMessage,
                        width: '80%',
                        disableClose: true
                    });
                }
                this.packageSelectorChange();
            } else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 5000,
                    verticalPosition: 'top',
                    data: ["selected Event type is not applicable for selected items"]
                });
                setTimeout(() => {
                    thisPage.selectedClass = thisPage.packageFilter != undefined ? thisPage.packageFilter.PackageClass : environment.DefaultPackageClassId;
                    thisPage.cd.markForCheck();
                }, 100);
            }
        } else {
            this.packageSelectorChange();
        }
    }

    priceCalculation() {
        var thisPage = this;
        let selectedPackageClass = this.selectedClass;
        this.selectedPackageClass = this.packageClasses.find(a => a.Id == this.selectedClass);

        let selectedPackageClassDetail = environment.PackageClasses.find(a => a.Id == this.selectedClass);
        if (selectedPackageClassDetail == undefined || (selectedPackageClassDetail.PriceMinConsessionId == undefined && selectedPackageClassDetail.PriceMaxConsessionId == undefined))
            return;
        this.facilitySummaries.forEach(function (facilitySummary) {
            //FacilityAdaptor.populateItemPrice(facilitySummary, selectedPackageClass);
            if (facilitySummary.upsellSummaries != undefined && facilitySummary.upsellSummaries.length > 0) {
                let time = 0;
                facilitySummary.upsellSummaries.forEach(function (us) {
                    if (us.minPrice == undefined || us.maxPrice == undefined || (us.minPrice.priceTypeId != 5 && us.minPrice.priceTypeId != 7 && us.maxPrice.priceTypeId != 5 && us.maxPrice.priceTypeId != 7)) {
                        time = time + 150;
                        setTimeout(function () {
                            thisPage.calculateUpsellItemPrice(facilitySummary, us, facilitySummary.startTime, facilitySummary.endTime);
                        }, time);
                    }
                });
            }
        });
    }

    calculateUpsellItemPrice(facilitySummary, upsell: UpsellSummary, startTime, endTime) {
        var thisPage = this;
        if (this.selectedPackageClass == undefined) return;

        upsell.minPrice = undefined;
        upsell.maxPrice = undefined;
        upsell.startTime = Utility.convertToISO(Utility.convertISOToDate(startTime));
        upsell.endTime = Utility.convertToISO(Utility.convertISOToDate(endTime));

        let packageClassId = (this.selectedPackageClass && !isNaN(this.selectedPackageClass)) ? this.selectedPackageClass : environment.DefaultPackageClassId;
        let concessionsId = FacilityAdaptor.getBookingPriceConcession(this.customerDefaultPriceConsessionId, this.isAllImmediateConfirmed);
               
        let res = FacilityAdaptor.getMinMaxPriceConcessionForUpsell(concessionsId, packageClassId);                     
        let minConcessionsId = res.minPriceConcession;
        let maxConcessionsId = res.maxPriceConcession;
                      
        // for min price 
        let count = 0
        let resultMin = this._itemService.CalculateItemPrice(upsell.id, minConcessionsId, upsell.qty, upsell.startTime, upsell.endTime);
        resultMin.subscribe((result) => {
            if (result.calculatedItemPriceGroups != undefined && result.calculatedItemPriceGroups.length > 0) {
                result.calculatedItemPriceGroups.forEach((cip, i) => {
                    if (!(cip instanceof Array)) {
                        if (cip.priceIncludingTax != 0)
                            upsell.minPrice = cip;
                    }
                });
            }
            count++;
            if (count == 2) this.setUpsellPrices(upsell, facilitySummary);
        },
            err => {
                console.log("Error : calculateUpsellItemPrice - min  " + upsell.name, err);
                count++;
                if (count == 2) this.setUpsellPrices(upsell, facilitySummary);
            }
        );

        // for max price 
        setTimeout(function () {
            let resultMax = thisPage._itemService.CalculateItemPrice(upsell.id, maxConcessionsId, upsell.qty, upsell.startTime, upsell.endTime);
            resultMax.subscribe((result) => {
                if (result.calculatedItemPriceGroups != undefined && result.calculatedItemPriceGroups.length > 0) {
                    result.calculatedItemPriceGroups.forEach((cip, i) => {
                        if (!(cip instanceof Array)) {
                            if (cip.priceIncludingTax != 0)
                                upsell.maxPrice = cip;
                        }
                    });
                }
                count++;
                if (count == 2) thisPage.setUpsellPrices(upsell, facilitySummary);
            },
                err => {
                    console.log("Error : calculateUpsellItemPrice - max  " + upsell.name, err);
                    count++;
                    if (count == 2) thisPage.setUpsellPrices(upsell, facilitySummary);
                }
            );
        }, 80);
    }

    setUpsellPrices(upsell: UpsellSummary, facilitySummary) {
        upsell.minPrice = (upsell.minPrice == undefined || upsell.minPrice == null) ? upsell.maxPrice : upsell.minPrice;
        upsell.maxPrice = (upsell.maxPrice == undefined || upsell.maxPrice == null) ? upsell.minPrice : upsell.maxPrice;
        this.store.dispatch(new UpdateFacility(facilitySummary));
    }

    packageChange() {
        if (this.selectedPackage == "") {
            this.selectedPackage = null;
            this.selectedPackageDetail = null;
            this.isValidPackage = false;
            this.packageSelectorChange()
        } else {
            this.isValidPackage = this.packageSeletedValidation();
        }
    }

    validatePackage(val) {
        this.isValidPackage = this.packageSeletedValidation();
        this.cd.detectChanges();
    }

    packageSeletedValidation() {
        let selectedFaclities = this.facilitySummaries;
        if (this.selectedPackage == undefined || this.selectedPackage == null) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Please select the event"]
            });
            return false;
        }

        
        let selectedPackage = this.packages.find(p => p.id == this.selectedPackage);
        if (!this.packageSelected || this.packageSelected.id != selectedPackage.id) {
            if (this.selectedPackageDetail != null) {
                if (this.selectedPackageDetail.id == selectedPackage.id) {
                    this.packageSelected = this.selectedPackageDetail;
                }
                else {
                    this.packageSelected = selectedPackage;
                }
            }
            else {
                this.packageSelected = selectedPackage;
            }
            
        }
        let isMatch = true;

        if (selectedPackage) {
            selectedFaclities.forEach(function (fc) {
                if (isMatch) {
                    var index = selectedPackage.salesCategories.findIndex(sc => fc.item.salesCategories.findIndex(isc => isc.id == sc) >= 0);
                    if (!(index != undefined && index >= 0)) {
                        isMatch = false;
                    }
                }
            });
            if (!isMatch) {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Selected event is not matched with selected facilities"]
                });
            } else {

                if (this.selectedPackageDetail == null) {
                    this.selectedPackageDetail = this.packages.find(p => p.id == this.selectedPackage);                   
                }             
                this.packageSelectorChange(this.packageSelected);                   
            }
        } else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Selected event is not matched with selected facilities"]
            });
            isMatch = false;
        }
        if (this.packageSelected.question && this.packageSelected.question.length > 0)
        {
            if (isMatch && this.answeredCurrentCount(this.packageSelected.question) == selectedPackage.question.length) {
                this.popPackageQuestions(this.packageSelected);
            }  
        }
             
        return isMatch;
    }

    selectPackageList() {
        let selectedFaclities = this.facilitySummaries;
        let filterdPackages = [];
        let packageFilterdByClass = this.packages.filter(a => a.packageClassId == +this.selectedClass);
        if (packageFilterdByClass.length == 0) {
            this.selectedPackage = null;
            this.selectedPackageDetail = null;
            return filterdPackages;
        }

        packageFilterdByClass.forEach(function (pk) {
            let isMatch = true;
            selectedFaclities.forEach(function (fc) {
                if (isMatch) {
                    if (pk.salesCategories != undefined) {
                        var index = pk.salesCategories.findIndex(sc => fc.item.salesCategories.findIndex(isc => isc.id == sc) >= 0);
                        if (!(index != undefined && index >= 0)) {
                            isMatch = false;
                        }
                    } else isMatch = false;
                }
            });
            if (isMatch) {
                filterdPackages.push(pk);
            }
        });
        return filterdPackages;
    }

    clickSubmitEnquiry(val) {
        this.navigated.emit(val);
    }

    facilitySearch() {
        this.router.navigate([RoutePath.Facility]);
    }

    //#region File upload


 
    public dropped(event: UploadEvent) {
        this.files = event.files;
        let snack = this.snackBar;
      
        let ds = this.bookingData.BookingConfig.MaximumDocUploadSize; 

        for (const droppedFile of event.files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.checkFileExtesion(file.name)) {
                        if (file.size < ds * 1048576) {
                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                let aHD = new AdHocDocument();
                                aHD.name = file.name;
                                aHD.fileSize = this.bytesToSize(file.size);

                                let stringdata = myReader.result.toString().split(',');
                                aHD.documentFile = stringdata[stringdata.length - 1];
                                aHD.file = file;
                                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                                this.store.dispatch(new AddDocument(aHD));
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Your request could not be processed. You have attempted to upload a document larger than "+ ds + " MB in size"]
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
                        });
                    }
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    onFileChanged(event) {
       
        let ds = this.bookingData.BookingConfig.MaximumDocUploadSize; 

        if (this.checkFileExtesion(event.target.files[0].name)) {
            if (event.target.files[0].size < ds * 1048576) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    let aHD = new AdHocDocument();
                    aHD.file = event.target.files[0];
                    aHD.name = event.target.files[0].name;
                    aHD.fileSize = this.bytesToSize(event.target.files[0].size);
                    let stringdata = myReader.result.toString().split(',');
                    aHD.documentFile = stringdata[stringdata.length - 1];
                    aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                    this.store.dispatch(new AddDocument(aHD));
                    this.fileInputTagVariable.nativeElement.value = "";
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Your request could not be processed. You have attempted to upload a document larger than " + ds + " MB in size"]
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
            });
        }
    }

    removeFile(file: AdHocDocument) {
        this.store.dispatch(new DeleteDocument(file));
    }

    bytesToSize(bytes) {
        if (bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
        }
    }

    //#endregion

    public openEditPopup(val: FacilitySummary) {
        let selectedPackageClass = this.packageClasses.find(a => a.Id == this.selectedClass);
        let dialogRef = this.dialog.open(EditModalComponent, {
            data: { facility: val, selectedPackageClass: selectedPackageClass },
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.store.dispatch(new UpdateFacility(result));
            this.cd.detectChanges();
        });
    }

    get64StringData(file: AdHocDocument) {
        if (file && file.file && file.file.size) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
        }
        else return null;
    }

    checkFileExtesion(name: string) {
        let supportTypes = ["doc", "docx", "pdf", "txt", "xls", "xlsx", "jpeg", "jpg", "png"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
    }

    deleteItem(obj: FacilitySummary) {
        let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            data: {
                message: 'Are you sure you need to delete this venue?',
                yesText: 'YES',
                noText: 'NO'
            },
            panelClass: ['custom-dialog-container', 'w30modal'],
            height: 'auto',
            width: '30%'
        });

        const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
            if (data == true) {
                this.store.dispatch(new DeleteFacility(obj.index));
            }
        });
    }

    changeStatus(state) {
        this.value = state;
        this.status = false;
    }

    preventWindowDragEvent() {
        window.addEventListener("drop", e => { e && e.preventDefault(); }, false);
        window.addEventListener("dragover", e => { e && e.preventDefault(); }, false);
    }

    //#region Upsell 

    popupExtra(facilitySummary: FacilitySummary) {
        this.dialog.open(UpsellComponent, {
            data: { fc: facilitySummary, isEdit: false },
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    editExtra(facilitySummary, upsellSummary) {
        this.dialog.open(UpsellComponent, {
            data: { fc: facilitySummary, us: upsellSummary, isEdit: true },
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    deleteUpsellItem(facilitySummary: FacilitySummary, upsellSummary: UpsellSummary) {
        let indexnum = facilitySummary.upsellSummaries.findIndex(x => x.id == upsellSummary.id);
        if (indexnum != undefined) {
            facilitySummary.upsellSummaries.splice(indexnum, 1);
            this.store.dispatch(new UpdateFacility(facilitySummary));
        }
    }

    //#endregion

    //#region Questions 

    popupQuestion(fc: FacilitySummary, item: Item, isUpsellItem: boolean) {
        this.dialog.open(QuestionnaireComponent, {
            data: { fc: fc, item: item, isUpsellItem: isUpsellItem },
            panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
            height: 'auto',
            width: '80%'
        });
    }

    popPackageQuestions(selectedPackage: BespokePackage) {

        if (selectedPackage) {
            this.packageFilter.PackageDetail = selectedPackage;
        }
        let filter = this.packageFilter;
        filter.BussinessAreaId = +this.selectedBusinessArea;
        filter.PackageClass = this.selectedClass;
        filter.Package = +this.selectedPackage;
        //filter.PackageDetail = this.selectedPackageDetail;
        filter.snackBar = this.snackBar;
        filter.EventName = this.defaultEventName;
        filter.ledgerNumber = this.ledgerNumber;

        this.dialog.open(QuestionnaireComponent, {
            data: { packageFilter: filter },
            panelClass: ['custom-dialog-container', 'w80modal', 'modal-width', 'extrapop'],
            height: 'auto',
            width: '80%'
        });
    }
    answeredCurrentCount(question) {
        var count = 0;
        for (var q of question) {
            if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion") && (q.answeredText != undefined && q.answeredText != null && q.answeredText != "")) {
                count++;
            } else if (q.type == "multipleChoiceQuestion") {
                var answeredCount = 0;
                if (q.answerChoices) {
                    q.answerChoices.forEach((answ, indx) => {
                        if (q.quantityRequired) {
                            if (answ.Quantity) answeredCount++;
                        } else {
                            if (answ.isSelectedAnswer) answeredCount++;
                        }
                    });
                    if (answeredCount > 0) count++;
                }
            }
        }
        return (question.length) - count;
    }

    //#endregion
    /*
    getItemPrices(facilitySummaries: FacilitySummary[]) {
        var thisPage = this;
        let priceConsession = { minConsessionId: this.systemDefaultPriceConsessionId, maxConsessionId: 0 };

        if (environment.PriceDisplayOption == 3 && (!this.isLoggedIn || (this.isLoggedIn && (this.customerDefaultPriceConsessionId == 0 || this.customerDefaultPriceConsessionId == null)))) {
            let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
            if (selectedPackageClass.PriceMinConsessionId != "" && selectedPackageClass.PriceMaxConsessionId != "") {
                priceConsession.minConsessionId = selectedPackageClass.PriceMinConsessionId;
                priceConsession.maxConsessionId = selectedPackageClass.PriceMaxConsessionId;
            }
        }

        let facilitySummaryRequest = [];
        let upsellItemRequest = [];

        facilitySummaries.forEach((facilitySummary, index) => {
            if (facilitySummary.item) {
                let item = {
                    index: index,
                    itemId: facilitySummary.item.id,
                    minPriceConcessionId: (this.isLoggedIn && (this.customerDefaultPriceConsessionId != 0 && this.customerDefaultPriceConsessionId != null)) ? +this.customerDefaultPriceConsessionId : (facilitySummary.item.allowImmediateConfirmedBookings == 1 && this.isSystemAllowedImmediateConfirmation) ? +this.systemDefaultPriceConsessionId : +priceConsession.minConsessionId,
                    maxPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && this.isSystemAllowedImmediateConfirmation) ? 0 : +priceConsession.maxConsessionId,
                    quantity: 1,
                    startTime: Utility.convertToISO(Utility.convertISOToDate(facilitySummary.startTime)),
                    endTime: Utility.convertToISO(Utility.convertISOToDate(facilitySummary.endTime))
                };
                facilitySummaryRequest.push(item);
                if (facilitySummary.upsellSummaries != undefined && facilitySummary.upsellSummaries.length > 0) {
                    //     let time = 0;
                    facilitySummary.upsellSummaries.forEach(function (us) {
                        if (us.minPrice == undefined || us.maxPrice == undefined || (us.minPrice.priceTypeId != 5 && us.minPrice.priceTypeId != 7 && us.maxPrice.priceTypeId != 5 && us.maxPrice.priceTypeId != 7)) {

                            let upselItem = {
                                index: index,
                                itemId: us.id,
                                minPriceConcessionId: (thisPage.isLoggedIn && (thisPage.customerDefaultPriceConsessionId != 0 && thisPage.customerDefaultPriceConsessionId != null)) ? +thisPage.customerDefaultPriceConsessionId : (facilitySummary.item.allowImmediateConfirmedBookings == 1 && thisPage.isSystemAllowedImmediateConfirmation) ? +thisPage.systemDefaultPriceConsessionId : +priceConsession.minConsessionId,
                                maxPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && thisPage.isSystemAllowedImmediateConfirmation) ? 0 : +priceConsession.maxConsessionId,
                                quantity: us.qty,
                                startTime: Utility.convertToISO(Utility.convertISOToDate(facilitySummary.startTime)),
                                endTime: Utility.convertToISO(Utility.convertISOToDate(facilitySummary.endTime))
                            };
                            upsellItemRequest.push(upselItem);
                        }
                    });
                }
            }
        });


        if (facilitySummaryRequest != undefined && facilitySummaryRequest.length > 0) {
            let itemPrice = this._itemService.GetItemPrices(facilitySummaryRequest);
            itemPrice.subscribe(result => {
                if (result != undefined)
                    result.forEach(data => {

                        let facility = JSON.parse(JSON.stringify(facilitySummaries[data.index]));
                        this.store.dispatch(new UpdateFacilityPrice(facility));

                        facilitySummaries[data.index].minPrice = new ItemPriceGroup;
                        facilitySummaries[data.index].maxPrice = new ItemPriceGroup;


                        facilitySummaries[data.index].minPrice.priceIncludingTax = data.minimumPrice;
                        facilitySummaries[data.index].maxPrice.priceIncludingTax = data.maximumPrice;

                        if (facilitySummaries[data.index].upsellSummaries.length == 0) {
                            this.store.dispatch(new UpdateFacility(facilitySummaries[data.index]));
                        }



                        //Get Upsell Prices
                        if (upsellItemRequest.length > 0) {
                            let upsellItemPrice = this._itemService.GetItemPrices(upsellItemRequest);
                            upsellItemPrice.subscribe(result => {
                                if (result != undefined)
                                    result.forEach(data => {
                                        let facility = JSON.parse(JSON.stringify(facilitySummaries[data.index]));
                                        if (facility.upsellSummaries != undefined && facility.upsellSummaries.length > 0) {
                                            let UpsellSummary: UpsellSummary = facility.upsellSummaries.filter(upsell => upsell.id == data.itemId);
                                            let indexnum = facility.upsellSummaries.findIndex(x => x.id == data.itemId);
                                            if (UpsellSummary != undefined && UpsellSummary != null) {

                                                UpsellSummary[indexnum].minPrice = new ItemPriceGroup;
                                                UpsellSummary[indexnum].maxPrice = new ItemPriceGroup;

                                                UpsellSummary[indexnum].minPrice.priceIncludingTax = data.minimumPrice;
                                                UpsellSummary[indexnum].maxPrice.priceIncludingTax = data.maximumPrice;
                                                facility.upsellSummaries[indexnum] = UpsellSummary[indexnum];
                                                facilitySummaries[data.index] = facility;
                                                this.store.dispatch(new UpdateFacility(facilitySummaries[data.index]));
                                            }
                                        }
                                    });
                            });
                        }

                    });
            });
        }
    }

    */
    //geting configuration
    getSelectconfigurations(facility: FacilitySummary) {
        //if (facility.selectedConfiguration != undefined) {
        //    return facility.selectedConfiguration;
        //}
        //if (facility.configurations != undefined){
        //let configuration = facility.configurations.find(a => a.id == facility.configuration.id);
        //return configuration;
        //}
        return facility.configuration;
    }

    getCartFacilityName(facility) {
        if (facility.name != undefined && facility.item.name != undefined) {
            if (facility.name == facility.item.name) {
                return facility.item.name;
            }
            return (facility.name + " : " + facility.item.name);
        }
    }
}
