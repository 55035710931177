﻿import { BaseResource } from '../base-resource';
import { Client, User, Venue, CommunicationMethod, Address } from './client';
import { Note } from 'src/modules/models/client/note';

export class Contact extends BaseResource {
    ref: string;
    client: Client;
    title: string;
    titleId: number;
    firstName: string;
    lastName: string;
    active: boolean;
    profilePicture: string;
    accountManager: User;
    address: Address;
    position: string;
    userName: string;
    decisionMaker: boolean;
    defaultVenue: Venue;
    communicationMethods: CommunicationMethod[];
    invoiceEmailAddress: string
    password: string;
    invoiceAddress: Address;
    deliveryAddress: Address;
    mailingAddress: Address
    bookingTypeList: BookingType[];
    clientVenueIds: VenueType[];
    notes: Note[];
    isPrimaryContact: boolean;
    dateRegistered: Date;
}

export class BookingType
{
    id: string;
    name: string
}


export class VenueType {
    id: string;
    name: string
}