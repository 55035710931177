import {
    Component, OnInit, ViewChild, Input,
    OnDestroy, Inject, HostListener, AfterViewInit, ViewChildren,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material';
import { PageEvent, MatPaginator, MatExpansionPanel } from '@angular/material';
import { environment } from 'src/environments/environment'
import { Observable, forkJoin, from, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { SystemConfig } from 'src/modules/system-configuration'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {AgmInfoWindow } from '@agm/core'
//store
import { Store, ICart, AddFacility, PackageSearch } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { FacilitySearchLoading, FacilitySearchLoaded, BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
//service
import { AssetService } from 'src/modules/services/asset.service';
import { PackageService } from 'src/modules/services/package.service';
import { ItemService } from 'src/modules/services/item.service';

import { BaseParam } from 'src/modules/models/base-param';
import { Assetsearchparams } from 'src/modules/models/asset/asset-search-params'
import { Configuration } from 'src/modules/models/asset/configuration';
import { ItemSearchParams } from 'src/modules/models/item/item-search-params'

import { FacilitySummary } from 'src/modules/models/asset/facility-summary'
import { BookingFacility } from 'src/modules/models/asset/booking-facility'
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { CalendarModalComponent } from 'src/modules/booking-portal/common/calendar-modal/calendar-modal.component';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots'

import { CurrencyFormat } from 'src/modules/currency-format';
import { Utility } from 'src/modules/utility';
import { MomentDateAdapter } from '../../../common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { WarningMessageComponent } from 'src/modules/shared/warning-message/warning-message.component';
import { FacilityCommonComponent } from 'src/modules/booking-portal/facility/facility-common.component';
//import { AlternativeOptionsComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/alternative-options/alternative-options.component';

import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

import { DecimalPipe } from '@angular/common';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

import { TimepickerConfig } from 'ngx-bootstrap/timepicker';


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        // { provide: TimepickerConfig, useFactory: getTimepickerConfig }
    ],

})
export class SearchComponent extends FacilityCommonComponent implements OnInit, OnDestroy, AfterViewInit {

    date = moment(); //default the filter date to three days in the future 
    cart$: Observable<ICart>;
    loader$: Observable<ILoadingStatus>;
    cart_subscriber: any;
    itemSubcription: any;
    searchstring: string;
    seletedStartTime: Date;
    seletedEndTime: Date;
    selectAttendees: number = 1;
    selectDate = moment()

    StartTime: Date;
    EndTime: Date;
    minDate: Date = new Date();//for date picker
    maxDate: Date;
    minStartDate: Date;
    maxStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isExpanded: boolean;
    isValidStart: boolean;
    isValidEnd: boolean;
    selectedClass: number;
    searchFacilities: FacilitySummary[];
    recordCount: number = 0;
    totalRecordCount: number = 0;
    pageSize = 5;
    pagenumber = 0;// this is for search   
    pageSizeOptions: number[] = [5, 10, 25, 100];

    userDefinedPageSizeBeforeMapViewToggle = 5;

    isAssending: boolean = true;
    sortingLable: string = "VENUE";
    sortingiconname: string = "mmc icon-sort";

    baseurl: string = environment.WebApiUrl;


    itemidlist: number[]
    timelable: string = "Any";
    timeSelection: string = "Any";


    showMapView: boolean = environment.ShowMapView && environment.ShowMapView.ShowMapView;
    view: string = "2";
    previousIW:AgmInfoWindow;

    //
    @ViewChild('facilityPaginator') paginator: MatPaginator;
    public innerWidth: any;
    textLimit;

    @ViewChild('myPanel') myPanel: MatExpansionPanel;
    matIcon = 'keyboard_arrow_down' || 'keyboard_arrow_up';

    @ViewChild('advSearchMenuTrigger') matMenuTrigger: MatMenuTrigger;
    options: FormGroup;
    Date: Date;
    hideme = [];
    packageFilter: any;
    addedFacilites: any;


    isConfigurationEnabled: boolean;
    isAllImmediateConfirmed: boolean;
    isLoggedIn: boolean;
    isSystemAllowedImmediateConfirmation: boolean;
    systemDefaultPriceConsessionId: number;
    customerDefaultPriceConsessionId: number;

    panelOpenState: number=-1;
    facilitySearchTextPlaceHolder: string;

    // Advance filters
    advanceSelectedFacilityType: string;
    facilityTypeDropdown: any;
    facilityTypeFilterSetting: any;

    advanceSelectedFacility: string = "";
    FacilityListForDropdown: any[] = [];
    facilityListFilterSetting: any;

    advancedfilterAtenndence: string = "0";
    attendeesFilterSetting: any;

    showAvailableFacilities: boolean;
    appeSettingData: AppSetting;
    isEnableAvailableFacilitiesOption: boolean;
    lat: number = -37.758253;
    lng: number = 145.1863441;
    zoom: number = 10;
    FacilityScreenMainDescription: any;



    webAPiBaseUrl: string;
    constructor(fb: FormBuilder,
        private dialog: MatDialog,
        _assetService: AssetService,
        private _packageService: PackageService,
        private store: Store<any>,
        private _sysConfig: SystemConfig,
        private _itemService: ItemService,
        private scrollTopService: ScrollTopService,
        @Inject(DOCUMENT) document,
        private chRef: ChangeDetectorRef,
        public snackBar: MatSnackBar,
        private sanitizer: DomSanitizer
    ) {
        super(_assetService);
        this.StartTime = new Date();
        this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

        var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        this.date = moment().add(leadDays, 'days');
        if (environment.DisableDatePickerForLeadDays) {
            var CurrentDate = new Date();
            CurrentDate.setDate(CurrentDate.getDate() + leadDays)
            this.minDate = CurrentDate;



        }

        if (this.showMapView) {
            this.view = environment.ShowMapView && environment.ShowMapView.IsDefault ? "1" : "2";
        }


        var _appSettings = environment.AppSetting as AppSetting;
        this.facilitySearchTextPlaceHolder = _appSettings.FacilityPageSetting.FacilitySearchTextPlaceHolder;
        this.isEnableAvailableFacilitiesOption = _appSettings.FacilityPageSetting.EnableAvailableFacilitiesOption;

        this.options = fb.group({
            color: 'primary',
            fontSize: [16, Validators.min(10)],
        });


        this.cart$ = this.store.select('cart');
        this.loader$ = this.store.select('loader');


        this.initializeTime();
        this.store.dispatch(new PackageSearch());
        this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;
        this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
        this.isConfigurationEnabled = environment.ConfigurationSelection.Enabled;

        // Advance Filter Mapping start
        if (environment.FacilityFilters && environment.FacilityFilters.length) {
            this.facilityTypeFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "facilityType");
            this.facilityListFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "facility");
            this.attendeesFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "attendees");
        }
        if (this.facilityListFilterSetting && this.facilityListFilterSetting.Visible) {
            this.loadFacilityList();
            if (this.facilityListFilterSetting.DefaultValue != undefined && this.facilityListFilterSetting.DefaultValue != "")
                this.advanceSelectedFacility = this.facilityListFilterSetting.DefaultValue
        }
        if (this.facilityTypeFilterSetting && this.facilityTypeFilterSetting.Visible) {
            this.loadFacilityTypeList();
            if (this.facilityTypeFilterSetting.DefaultValue != undefined && this.facilityTypeFilterSetting.DefaultValue != "")
                this.advanceSelectedFacilityType = this.facilityTypeFilterSetting.DefaultValue
        }
        if (this.attendeesFilterSetting && this.attendeesFilterSetting.Visible && this.attendeesFilterSetting.DefaultValue != undefined && this.attendeesFilterSetting.DefaultValue != "") {
            this.advancedfilterAtenndence = this.attendeesFilterSetting.DefaultValue
        }
        // Advance Filter Mapping End

        this.cart_subscriber =
            this.cart$.subscribe(state => {
                console.log("STATE -- ", state);
                this.packageFilter = state.packageFilter;
                this.addedFacilites = state.addedFacilites;
                this.isLoggedIn = (state.contact.client != undefined && state.contact.client.clientName != "") ? true : false;
                this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
                this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;

            });
        this.innerWidth = window.innerWidth;
        if (innerWidth >= 768) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitHigh
        } else if (innerWidth < 768 && innerWidth > 400) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitMedium
        } else {
            this.textLimit = environment.BookingSearchDescriptionTextLimitLow
        }

        this.showAvailableFacilities = this.isEnableAvailableFacilitiesOption ? true : false;

        this.onsearch();



        this.FacilityScreenMainDescription = this.sanitizer.bypassSecurityTrustHtml(_appSettings.FacilityPageSetting.FacilityScreenMainDescription);

    }

    ngAfterViewInit() {
        this.chRef.detectChanges();
    }

    loadFacilityTypeList() {
        this._assetService.GetAssetType().subscribe(res => {
            this.facilityTypeDropdown = res;
        }, error => {
            // this.store.dispatch(new FacilitySearchLoaded());
        });
    }
    //time picker section start

    dateChanged() {

        var selectedDate = this.date.toDate();
        this.date.set({
            hour: this.StartTime.getHours(),
            minute: this.StartTime.getMinutes(),
            second: this.StartTime.getSeconds()
        });
        var currenDate = new Date();
        if (selectedDate > currenDate) {
            this.StartTime = Utility.roundToMinDuration(this.date.toDate(), environment.MinimumBookingDuration).newDate; // start with current date
            this.minStartDate = Utility.roundToMinDuration(this.date.toDate(), environment.MinimumBookingDuration).newDate; // add 30 minutes to current time

        } else {
            this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate; // start with current date
            this.minStartDate = Utility.roundToMinDuration(new Date, environment.MinimumBookingDuration).newDate; // add 30 minutes to current time

        }

        this.minEndDate = moment(this.minStartDate).add(environment.MinimumBookingDuration, 'm').toDate();
        this.maxStartDate = new Date(this.StartTime);
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();

        if (this.StartTime.getHours() == 23 && this.StartTime.getMinutes() == 30) {
            this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration - 1, 'm').toDate();
        } else {
            this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        }

        let newEndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        newEndTime.setHours(this.EndTime.getHours());
        newEndTime.setMinutes(this.EndTime.getMinutes());

        this.EndTime = newEndTime;

        var dt = this.date.toDate();

        this.StartTime = Utility.changeDatePart(this.StartTime, this.date.toDate());
        var today = new Date();
        if (!(dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
            dt.setHours(0, 0, 0);
            this.minStartDate = dt;

        } else {

            if (new Date(this.StartTime) < new Date()) {
                this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
            }
            else {
                let newstartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                newstartTime.setHours(this.StartTime.getHours());
                newstartTime.setMinutes(this.StartTime.getMinutes());
                this.StartTime = newstartTime;
            }
            this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;

        }

        this.maxStartDate = Utility.changeDatePart(new Date(this.maxStartDate), this.date.toDate());
        this.maxStartDate.setMinutes(this.maxStartDate.getMinutes() + 1);


        this.maxEndDate = this.date.toDate();
        this.maxEndDate.setHours(24, 0, 0);



        var selectedDate = this.date.toDate();
        selectedDate.setHours(0, 0, 0, 0);
        var futureDate = new Date(environment.LatestFutureBookingDate);
        futureDate.setHours(0, 0, 0);
        if (selectedDate > futureDate) {
            let errorMessageForFutureBookingDate = environment.LatestFutureBookingDateErrorMessage.replace("#LatestFutureBookingDate#", moment(environment.LatestFutureBookingDate).format("DD MMM YYYY").toString());
            this.dialog.open(WarningMessageComponent, {
                data: errorMessageForFutureBookingDate.replace("for #FacilityName#", ""),
                width: '80%',
                disableClose: true
            });
            return;
        }

        this.settimelable();
    }

    startChange(event) {
        //if (event != null && this.isValidStart)
        //    this.StartTime = event;
    }

    endChange(event) {
        //if (event != null && this.isValidEnd)
        //    this.EndTime = event;
    }

    startTimeChanged() {
        if (this.StartTime != null) {
            if (this.StartTime.getHours() == 23 && this.StartTime.getMinutes() > 30) {
                this.StartTime.setMinutes(30);
                this.StartTime = moment(this.StartTime).toDate();
            }
            var dtObj = Utility.roundToMinDuration(this.StartTime, environment.MinimumBookingDuration);
            if (dtObj.isDateChanged)
                this.StartTime = moment(dtObj.newDate).toDate();

            this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            if (moment(this.StartTime) >= moment(this.EndTime))
                this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            var dt = this.date.toDate();
            var today = new Date();
            if ((dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
                if (this.StartTime < today) {
                    this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                }
            }
            if (this.EndTime.getFullYear() != this.StartTime.getFullYear() ||
                this.EndTime.getMonth() != this.StartTime.getMonth() ||
                this.EndTime.getDate() != this.StartTime.getDate()) {

                this.EndTime.setFullYear(this.StartTime.getFullYear(), this.StartTime.getMonth(), this.StartTime.getDate());
            }
        }

    }

    endTimeChanged() {

        if (this.EndTime != null) {
            if (this.EndTime.getFullYear() == this.maxEndDate.getFullYear() &&
                this.EndTime.getMonth() == this.maxEndDate.getMonth() &&
                this.EndTime.getDate() == this.maxEndDate.getDate() &&
                this.EndTime.getHours() == this.maxEndDate.getHours() &&
                this.EndTime.getMinutes() == this.maxEndDate.getMinutes()
            ) {
                this.EndTime = moment(this.EndTime).add(-1, 'm').toDate();

            } else {

                var dtObj = Utility.roundToMinDuration(this.EndTime, environment.MinimumBookingDuration, true);
                if (dtObj.isDateChanged)
                    this.EndTime = moment(dtObj.newDate).toDate();

                if (moment(this.StartTime) >= moment(this.EndTime))
                    this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            }
        }

        return this.EndTime;
    }

    initializeTime(): void {

        this.StartTime = Utility.roundToMinDuration(this.date.toDate(), environment.MinimumBookingDuration).newDate;
        this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.minEndDate = moment(new Date()).add(environment.MinimumBookingDuration, 'm').toDate();
        if (this.StartTime.getHours() == 23 && this.StartTime.getMinutes() == 30) {
            this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration - 1, 'm').toDate();
        } else {
            this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        }
        this.minEndDate = this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.maxStartDate = this.date.toDate();
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();
        this.maxEndDate = this.date.toDate();
        this.maxEndDate.setHours(24, 0, 0);

    }

    //time picker section end
    getFontSize() {
        return Math.max(10, this.options.value.fontSize);
    }



    ngOnInit() {

        //   this.maxDate = new Date(environment.LatestFutureBookingDate);
        this.webAPiBaseUrl = environment.WebApiUrl;
        this.appeSettingData = (environment.AppSetting as AppSetting);


        if (this.appeSettingData.FacilityPageSetting.SearchGoogleMapCenterLatitude)
            this.lat = this.appeSettingData.FacilityPageSetting.SearchGoogleMapCenterLatitude;

        if (this.appeSettingData.FacilityPageSetting.SearchGoogleMapCenterLongitude)
            this.lng = this.appeSettingData.FacilityPageSetting.SearchGoogleMapCenterLongitude;

        if (this.appeSettingData.FacilityPageSetting.SearchGoogleMapZoom) {
            this.zoom = this.appeSettingData.FacilityPageSetting.SearchGoogleMapZoom;
        }


        if (this.myPanel != undefined) {

            this.myPanel.expandedChange.subscribe((data) => {
                this.matIcon = data ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
            });

        }

        console.log(this.searchFacilities);


    }


    toggleAlternativeItems(facility) {
        facility.isDefultExpandAlternativeConfiguration = !facility.isDefultExpandAlternativeConfiguration;
    }

    setPanelStatus(index) {
        this.panelOpenState = index;
    }

    ngOnDestroy() {

        if (this.cart_subscriber) this.cart_subscriber.unsubscribe();
        if (this.itemSubcription) this.itemSubcription.unsubscribe();
    }

    pageclickevent(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.pagenumber = pageEvent.pageIndex;
        this.onsearch()
    }

    onEnter() {
        if (this.searchstring != undefined || this.searchstring != "") {
            this.onsearch()
        }
    }

    searchClick() {

        var selectedDate = this.date.toDate();
        selectedDate.setHours(0, 0, 0, 0);
        var futureDate = new Date(environment.LatestFutureBookingDate);
        futureDate.setHours(0, 0, 0);
        if (selectedDate > futureDate) {
            let errorMessageForFutureBookingDate = environment.LatestFutureBookingDateErrorMessage.replace("#LatestFutureBookingDate#", moment(environment.LatestFutureBookingDate).format("DD MMM YYYY").toString());
            this.dialog.open(WarningMessageComponent, {
                data: errorMessageForFutureBookingDate.replace("for #FacilityName#", ""),
                width: '80%',
                disableClose: true
            });
            return;
        }

        this.pagenumber = 0;
        this.onsearch()


    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }


    onsearch() {
        this.hideme = [];
        let searchParam = new Assetsearchparams();
        searchParam.pagesize = this.pageSize;
        searchParam.searchpharese = this.searchstring;
        searchParam.pagenumber = this.pagenumber + 1;

        if (this.showAvailableFacilities) {
            searchParam.starttime = this.getDatefomTime(this.StartTime);
            searchParam.endtime = this.getDatefomTime(this.EndTime);
            searchParam.duration = environment.MinimumBookingDuration;
            searchParam.considerOpeningHours = true;

            if (this.timelable == 'Any') {
                let dtS = new Date(this.date.year(), this.date.month(), this.date.date());
                let dtE = new Date(this.date.year(), this.date.month(), this.date.date());
                dtE.setHours(23);
                dtE.setMinutes(59);
                dtE.setSeconds(0);
                searchParam.starttime = this.getDatefomTime(dtS);
                searchParam.endtime = this.getDatefomTime(dtE);
                searchParam.considerOpeningHours = true;
            }
        }

        searchParam.filterattendens = this.advancedfilterAtenndence;
        searchParam.assetname = this.advanceSelectedFacility;
        searchParam.assetTypeId = this.advanceSelectedFacilityType;
        searchParam.timelable = this.timelable;
        if (this.packageFilter && this.packageFilter.PackageDetail && this.packageFilter.Package) {
            searchParam.SalesCategoryId = this.packageFilter.PackageDetail.salesCategories.join(',')
        }
        if (this.isAssending) {
            searchParam.sort = "Name";
        } else {
            searchParam.sort = "-Name";
        }

        this.seletedStartTime = this.StartTime;
        this.seletedEndTime = this.EndTime;
        this.selectDate = this.date;
        try {

            if (this.advancedfilterAtenndence != "" && this.advancedfilterAtenndence != null && this.advancedfilterAtenndence != undefined) {
                this.selectAttendees = parseInt(this.advancedfilterAtenndence);
            }
            else {
                this.selectAttendees = 0;
            }
        } catch (e) {
            this.selectAttendees = 0;
        }
        this.store.dispatch(new FacilitySearchLoading());
        let availablityResult = this._assetService.facilittySearch(searchParam, this.textLimit, this.showAvailableFacilities);
        availablityResult.subscribe((data) => {
            /*
            get the responce and assending ling oder by name 
            and set length for pagination and view
            
            */
            // console.log("recived data", data);
            //this.subjectF.next(data.facilities);


            this.searchFacilities = (data.facilities)

            this.recordCount = data.totalFacilities;

            this.totalRecordCount = data.unfilteredResults;

            //push itemIds only if list view
            if (this.view == '2') {
                this.itemidlist = data.itemList;
            }
            else {
                //clearing itemIdList to avoid unnecessary Api call in searchItem() 
                //when using map view
                this.itemidlist = [];
            }
        },
            err => {
                this.recordCount = 0;
                this.searchFacilities = []
                this.itemidlist = []

            },
            () => {
                this.scrollTopService.scrollPageTop();
                this.store.dispatch(new FacilitySearchLoaded());
                //load all data if not map view
                if (this.view == '2') {
                    this.searchItem();
                    let dt = new Date(this.date.year(), this.date.month(), this.date.date());
                    super.loadFacilityTimeslots(this.searchFacilities, dt);
                    var res = this._assetService.getFacilityThumbnails(this.searchFacilities);
                    if (res != null) {
                        var resPromise = res.toPromise();
                        resPromise.then(data => {
                            this.searchFacilities = data;
                        });
                    }
                }
            });

    }

    searchItem() {

        //var priceConsessions = [];
        //if (this.addedFacilites != null && this.addedFacilites != undefined && this.addedFacilites.length > 0) {
        //    if (this.isLoggedIn && this.customerDefaultPriceConsessionId != 0) {
        //        priceConsessions.push(this.customerDefaultPriceConsessionId)
        //    } else if (this.packageFilter != undefined && this.packageFilter.PackageClass != null && this.packageFilter.PackageClass != undefined && this.packageFilter.PackageClass != "0") {
        //        let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(this.addedFacilites, this.packageFilter.PackageClass);
        //        if (isPriceConcessionAvailable.isHasValidMinPriceConcession) priceConsessions.push(isPriceConcessionAvailable.PriceMinConsessionId);
        //        if (isPriceConcessionAvailable.isHasValidMaxPriceConcession) priceConsessions.push(isPriceConcessionAvailable.PriceMaxConsessionId);
        //    }
        //}

        if (this.itemidlist.length > 0) {
            let itemsearch = new ItemSearchParams();
            itemsearch.ItemTypeid = 1;

            itemsearch.itemidlist = this.itemidlist;
            itemsearch.FromDate = this.getDatefomTime(this.seletedStartTime);
            itemsearch.Todate = this.getDatefomTime(this.seletedEndTime);
            itemsearch.salesChannelId = environment.SalesChannel;


            if (this.timelable == 'Any') {
                let dtS = new Date(this.date.year(), this.date.month(), this.date.date());
                let dtE = new Date(this.date.year(), this.date.month(), this.date.date());
                dtE.setHours(23);
                dtE.setMinutes(59);
                dtE.setSeconds(0);
                itemsearch.FromDate = this.getDatefomTime(dtS);
                itemsearch.Todate = this.getDatefomTime(dtE);
            }
            this.store.dispatch(new FacilitySearchLoading());
            this.itemSubcription = this._itemService.itemSearch(itemsearch).subscribe((data) => {
                this.cart_subscriber = this.cart$.subscribe(cart => {
                    this.selectedClass = (cart.packageFilter != undefined && cart.packageFilter != null && !isNaN(cart.packageFilter.PackageClass)) ? cart.packageFilter.PackageClass : environment.DefaultPackageClassId;
                    FacilityAdaptor.PopulateFacilitySummary(this.searchFacilities, data, this.selectedClass,
                        FacilityAdaptor.getBookingPriceConcession(this.customerDefaultPriceConsessionId, this.isAllImmediateConfirmed), this.selectAttendees);

                    //   this.getItemPrices(this.searchFacilities);
                    //  this.chRef.detectChanges();
                });
            }, error => {

            }, () => {
                this.store.dispatch(new FacilitySearchLoaded());
                //    this.getItemPrices(this.searchFacilities);
            })
        }
    }


    getSerchbaleDatefomTime(selected: Date) {
        /*
        this will combine data picker date anda time form time piker and return Iso date string.
        the this.date refere to moment date object
        */
        let searchabledate = new Date(this.selectDate.year(), this.selectDate.month(), this.selectDate.date());
        searchabledate.setHours(selected.getHours(), selected.getMinutes(), selected.getSeconds());
        //return searchabledate.toISOString();
        return Utility.convertToISO(searchabledate);
    }

    getDatefomTime(selected: Date) {
        /*
        this will combine data picker date anda time form time piker and return Iso date string.
        the this.date refere to moment date object
        */
        let searchabledate = new Date(this.date.year(), this.date.month(), this.date.date());
        searchabledate.setHours(selected.getHours(), selected.getMinutes(), selected.getSeconds());
        //return searchabledate.toISOString();
        return Utility.convertToISO(searchabledate);
    }

    settimelable() {
        if (this.timeSelection != 'Any') {
            var start = moment(this.StartTime.toISOString())
            var end = moment(this.EndTime.toISOString())

            this.timelable = start.format('h:mm A') + "-" + end.format('h:mm A')
        } else {
            this.timelable = 'Any'
        }

        if (this.appeSettingData.FacilityPageSetting.AutomaticallySearchFacility) {
            this.onsearch();
        }
    }

    toggleSort() {

        this.isAssending = !this.isAssending
        this.sortingiconname = this.isAssending ? "mmc icon-sort" : "mmc icon-sort-1";
        this.pagenumber = 0;
        this.onsearch();
    }

    onsortAssending() {
        //this.isAssending = true;
        // this.sortingLable = "VENUE";
        // this.sortingiconname = "mmc icon-sort";
        //this.toggleSort();  
    }

    onsortDessendin() {
        //this.isAssending = false;
        //this.sortingLable = "VENUE";
        // this.sortingiconname = "mmc icon-sort-1";
        //this.toggleSort();
        //this.onsearch();     
    }

    advancedfilterSearch() {
        this.matMenuTrigger.closeMenu();
        if (this.date.toDate() > new Date(environment.LatestFutureBookingDate)) {
            let errorMessageForFutureBookingDate = environment.LatestFutureBookingDateErrorMessage.replace("#LatestFutureBookingDate#", moment(environment.LatestFutureBookingDate).format("YYYY MMM DD").toString());
            this.dialog.open(WarningMessageComponent, {
                data: errorMessageForFutureBookingDate.replace("for #FacilityName#", ""),
                width: '80%',
                disableClose: true
            });
            return;
        }
        this.pagenumber = 0;
        this.onsearch()

    }

    loadFacilityList() {
        //this.store.dispatch(new FacilitySearchLoading());
        this._assetService.facilityList().subscribe(res => {
            this.FacilityListForDropdown = res;
        }, error => {
            // this.store.dispatch(new FacilitySearchLoaded());
        });
    }

    bookingEnquiry(facility: FacilitySummary) {

        if (facility.item.availableTo != undefined && facility.item.availableTo != null) {
            if (Utility.convertISOToDate(this.getSerchbaleDatefomTime(this.seletedEndTime)) > Utility.convertISOToDate(facility.item.availableTo)) {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Enquiries can be made only till" + facility.item.availableTo.split('/[T]/')[0] + " for " + facility.name]
                });
                return;
            }
        }
        //Add facility validation
        var fc = new FacilitySummary();
        fc.id = facility.id;
        fc.item = facility.item;
        fc.items = facility.items;
        fc.itemPrice = facility.itemPrice;
        fc.configuration = facility.configuration;
        fc.configurations = facility.configurations;
        fc.itemPrice = facility.itemPrice;
        // fc.package = facility.package;
        fc.startTime = this.getSerchbaleDatefomTime(this.seletedStartTime);
        fc.endTime = this.getSerchbaleDatefomTime(this.seletedEndTime);
        fc.name = facility.name;
        fc.minPrice = facility.minPrice;
        fc.maxPrice = facility.maxPrice;
        fc.capacity = facility.capacity;
        fc.attendees = this.selectAttendees;
        fc.openingTime = facility.openingTime;
        fc.closingTime = facility.closingTime;
        fc.venue = facility.venue;
        fc.directions = facility.directions;
        fc.facilityTypeId = facility.facilityTypeId;
        var startTime = this.getSerchbaleDatefomTime(this.seletedStartTime);
        var endTime = this.getSerchbaleDatefomTime(this.seletedEndTime);

        if (facility.getAvailabilityLabel() == "PARTIALLY AVAILABLE" && this.timelable == "Any" || (this.selectAttendees == 0)) {

            let dialogRef =  this.dialog.open(EditModalComponent, {
                data: { facility: fc, isaddfacility: true },
                panelClass: ['custom-dialog-container', 'w60modal'],
                height: 'auto',
                width: '60%'
                // DialogPosition : top

            });

            dialogRef.afterClosed().subscribe(result => {
                //if (this.searchFacilities != undefined && this.searchFacilities.length > 0) {

                //    this.searchFacilities.forEach(facility => {

                //        FacilityAdaptor.populatePackageClassWiseItemPrice(facility.item, facility, this.selectedClass)
                //    })
                //}

            });
        } else if (this.timelable != "Any") {

            if (facility.configuration != undefined) {
                var tempInDate = new Date(this.seletedStartTime)
                var tempOutDate = new Date(this.seletedEndTime)
                if (facility.configuration.setupTime != undefined) {

                    tempInDate.setMinutes(tempInDate.getMinutes() - facility.configuration.setupTime)
                    startTime = this.getSerchbaleDatefomTime(tempInDate);
                }
                if (facility.configuration.teardownTime != undefined) {

                    tempOutDate.setMinutes(tempOutDate.getMinutes() + facility.configuration.teardownTime)
                    endTime = this.getSerchbaleDatefomTime(tempOutDate);
                }

                if (facility.configuration.setupTime != 0 || facility.configuration.teardownTime != 0) {
                    let availablityResult = this._assetService.timeslotSearch(fc.id, startTime, endTime);

                    availablityResult.subscribe(res => {
                        //this.isAvailable = (res.length < 1);
                        if (res.length < 1) {
                            this.store.dispatch(new AddFacility(fc));
                        } else {
                            var message = 'Timeslot unavailable: ' + tempInDate.toLocaleTimeString() + ' - ' + tempOutDate.toLocaleTimeString()
                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: [message]
                            });
                        }
                        //this.showUnavailableMessage = !(res.length < 1);
                    },
                        err => {
                            console.log("Error", err);
                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: []
                            });
                            //  this.store.dispatch(new FacilitySearchLoaded());
                        },
                        () => { },
                    );
                } else {
                    //if (environment.PriceDisplayOption == 3) {
                    //    FacilityAdaptor.populatePackageClassWiseItemPrice(fc.item, fc, this.selectedClass);
                    //}
                    this.store.dispatch(new AddFacility(fc));
                }
            }
            else {
                //if (environment.PriceDisplayOption == 3) {
                //    FacilityAdaptor.populatePackageClassWiseItemPrice(fc.item, fc, this.selectedClass);
                //}
                this.store.dispatch(new AddFacility(fc));
            }
        } else {
            //if (environment.PriceDisplayOption == 3) {
            //    FacilityAdaptor.populatePackageClassWiseItemPrice(fc.item, fc, this.selectedClass);
            //}
            this.store.dispatch(new AddFacility(fc));
        }

    }

    errorHandler(event) {
        event.target.src = environment.ApiUrl + "dist/assets/images/no-image.png";
    }



    openTimePopup(fac:FacilitySummary) {

        fac.calenderTimeslots = fac.calenderTimeslots.filter(x => !x.isSelectedTimeSlot);

        let searchdate = new Date(this.date.year(), this.date.month(), this.date.date());
        let selectedStart = Utility.appendTimePart(searchdate, Utility.convertISOToDate(this.StartTime), true);
        let selectedEnd = Utility.appendTimePart(searchdate, Utility.convertISOToDate(this.EndTime), true);
        FacilityAdaptor.populateSelectedTimeslot(fac, selectedStart, selectedEnd);

        this.dialog.open(CalendarModalComponent, {
            panelClass: ['modal-width', 'facility-calendar-modal'],
            height: 'auto',
            //width: '60%',
            data: { slots: fac.calenderTimeslots, availableSlots: fac.availableTimeslots, configuration: fac.getSelectedConfiguration(), isAny: this.timelable === "Any" ? true : false },
            // DialogPosition : top
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if (innerWidth >= 768) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitHigh
        } else if (innerWidth < 768 && innerWidth > 400) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitMedium
        } else {
            this.textLimit = environment.BookingSearchDescriptionTextLimitLow
        }
    }

    toggelReadMore(obj, id) {
        if (document.getElementById(id + "-expand").style.display === "none") {
            document.getElementById(id + "-preview").style.display = 'none';
            document.getElementById(id + "-expand").style.display = 'inline';
            document.getElementById(id + "-linkshow").style.display = 'none';
            document.getElementById(id + "-linkhide").style.display = 'inline-block';
        } else {
            document.getElementById(id + "-preview").style.display = 'inline';
            document.getElementById(id + "-expand").style.display = 'none';
            document.getElementById(id + "-linkshow").style.display = 'inline-block';
            document.getElementById(id + "-linkhide").style.display = 'none';
        }

        //@ViewChild('myDiv') myDiv: ElementRef;
    }

    modelChanged(newObj) {
        this.pagenumber = 0;
    }

    toggleView(value) {
        console.log(value);
        if (value == 1) {
            this.view = '1';
            this.userDefinedPageSizeBeforeMapViewToggle = this.pageSize;
            this.pageSize = 2000;
        }
        else {
            this.view = '2';
            this.pageSize = this.userDefinedPageSizeBeforeMapViewToggle;
        }
        this.onsearch();
    }
    previous;
    clickedMarker(infowindow) {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
    }

    trackerFunction(index, facility) { return `${index}-${facility.id}`; }

    //openAlternativeOptions(): void {
    //    const dialogRef = this.dialog.open(AlternativeOptionsComponent, {
    //        panelClass: ['w80modal', 'dialog-container-common'],
    //        width: '250px'
    //    });
    //}


    getMinMaxPrice(itm, facility) {
        let selectedfacility = this.searchFacilities.find(f => f.id == facility.id);


    }


    changeFacilityItem(item, facility) {
        FacilityAdaptor.changeFacilityItem(facility, item);
    }

    isUnitPriceRange(item) {
        if (item == undefined) return false;

        item.itemMinPrice = (item.itemMinPrice) ? item.itemMinPrice : item.itemMaxPrice;
        item.itemMaxPrice = (item.itemMaxPrice) ? item.itemMaxPrice : item.itemMinPrice;
        if (item.itemMinPrice == undefined || item.itemMaxPrice == undefined)
            return false;

        var min = (item.itemMinPrice) ? item.itemMinPrice!.priceIncludingTax : 0;
        var max = (item.itemMaxPrice) ? item.itemMaxPrice!.priceIncludingTax : 0;

        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    }

    showItemsAvailability(facility) {
        if (facility.items != undefined && this.isConfigurationEnabled == true) {
            if (facility.items.length > 1)
            {
                return true;
            }
        }
        return false;
    }

    //getFacilityPrice(facility) {

    //    if (facility.maxPrice != null) {
    //        return facility.maxPrice.priceIncludingTax;
    //    } else if (facility.minPrice != null) {
    //        return facility.minPrice.priceIncludingTax;
    //    }

    //}

    getConfigurationData(facility) {
        var getFacilityConfiguration = FacilityAdaptor.getAdapterSelectedConfiguration(facility);
        return getFacilityConfiguration;
    }
    enableBookButton(facility:FacilitySummary)
    {
        return facility.item && facility.configuration && (facility.item.itemMinPrice || facility.item.itemMaxPrice)
    }

    clear() {
        this.advancedfilterAtenndence = "0";
        this.advanceSelectedFacility = "";
        this.advanceSelectedFacilityType = "";

        // Advance Filter Mapping start

        if (environment.FacilityFilters && environment.FacilityFilters.length) {
            this.facilityTypeFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "facilityType");
            this.facilityListFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "facility");
            this.attendeesFilterSetting = environment.FacilityFilters.find(ff => ff.Id == "attendees");
        }
        if (this.facilityListFilterSetting && this.facilityListFilterSetting.Visible) {
            if (this.facilityListFilterSetting.DefaultValue != undefined && this.facilityListFilterSetting.DefaultValue != "")
                this.advanceSelectedFacility = this.facilityListFilterSetting.DefaultValue
        }

        if (this.facilityTypeFilterSetting && this.facilityTypeFilterSetting.Visible) {
            if (this.facilityTypeFilterSetting.DefaultValue != undefined && this.facilityTypeFilterSetting.DefaultValue != "")
                this.advanceSelectedFacilityType = this.facilityTypeFilterSetting.DefaultValue
        }
        if (this.attendeesFilterSetting && this.attendeesFilterSetting.Visible && this.attendeesFilterSetting.DefaultValue != undefined && this.attendeesFilterSetting.DefaultValue != "") {
            this.advancedfilterAtenndence = this.attendeesFilterSetting.DefaultValue
        }

        // Advance Filter Mapping End 


        this.searchstring = "";
        this.showAvailableFacilities = true;
        this.StartTime = new Date();
        this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

        var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        this.date = moment().add(leadDays, 'days');

        this.timeSelection = "Any";

        this.settimelable();
    }

    getAvailableFacility(event) {
        if (this.appeSettingData.FacilityPageSetting.AutomaticallySearchFacility) {
            this.onsearch();
        }
    }
    //map
    // other functions
    mapClick() {
        if (this.previousIW) {
            this.previousIW.close();
        }
    }

    markerClick(infoWindow, facility) {
        if (this.previousIW) {
            this.previousIW.close();
        }

        //clearing item id list  to fetch only item required by facility in searchItem()
        this.itemidlist = [];
        facility.items.forEach((item) => {
            this.itemidlist.push(+item.id);
        })
        this.searchItem();
        let dt = new Date(this.date.year(), this.date.month(), this.date.date());

        super.loadFacilityTimeslots(this.searchFacilities, dt);
        //temp variable to get only this facility thumbnail
        let tempFacilitySummaries: FacilitySummary[] = [];
        tempFacilitySummaries.push(facility);

        var res = this._assetService.getFacilityThumbnails(tempFacilitySummaries);
        if (res != null)
        {
            var resPromise = res.toPromise();
            resPromise.then((data: FacilitySummary[]) => {
                //update main variable
                var ind = this.searchFacilities.findIndex(x => x.id == facility.id);
                this.searchFacilities[ind] = data[0];
            }).then(() => {
                this.previousIW = infoWindow;
            });
        }
        else {
            this.previousIW = infoWindow;
        }

    }
}

